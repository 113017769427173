import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from "react"
import * as blogApi from "../../api/blogApi"
import {publishDraft, resetPostDraft} from "../../api/blogApi"
import {BlogPostEntity, BlogPostWithParse, PlatformLanguage} from "../../api/ParseObjects"
import {RouteComponentProps} from "@reach/router"
import {Layout} from "../../components/Layout"
import {Box, Fab, FormControl, Grid, InputLabel, Menu, MenuItem, Select, TextField} from "@material-ui/core"
import {connect, ConnectedProps, useDispatch} from "react-redux"
import {notification} from "../notification/notificationSlice"
import {PostCard} from "./components/PostCard"
import {useI18next} from "gatsby-plugin-react-i18next"
import styled from "@emotion/styled"
import {GrFormAdd} from "@react-icons/all-files/gr/GrFormAdd"
import {GrFormEdit} from "@react-icons/all-files/gr/GrFormEdit"
import {GrowGrid} from "../../components/Shared"
import {RiSave2Fill} from "@react-icons/all-files/ri/RiSave2Fill"
import {ImageUploader} from "../marketplace/components/ImageUploader"
import Parse from "parse"
import slugify from "react-slugify"
import {flags, getFlag} from "./components/Flags"
import {ResetDraftButton} from "./components/edit/ResetDraftButton"
import {RootState} from "../../store/store"
import {PublishDraftButton} from "./components/edit/PublishDraftButton"
import {PrivatePage} from "../../components/PrivateRoutePage"


export const FlagSelection = styled.a(({theme}) => ({
  position: "relative",
  marginBottom: "1em",
  "& .svgflag": {
    svg: {
      fontSize: "3em",
      border: "3px solid #00000000",
      borderRadius: "5px",
      cursor: "auto",
      marginRight: "0.5em",
      transition: "transform ease 0.5s",
      ":focus,:focus-within,:hover": {
        transform: "scale(1.1)",
        border: `3px solid ${theme.palette.secondary.main}`
      },
    },
  },
  "&.selected .svgflag": {
    svg: {
      border: `3px solid ${theme.palette.primary.main}`
    }
  }
}))

const FlagFab = styled(Fab)({
  position: "absolute",
  fontSize: "1.1em",
  width: "1.1em",
  height: "1.1em",
  minHeight: "1.1em",
  right: "1em",
  top: 0
})

const FullWithTextField = styled(TextField)({
  width: "100%",
  marginBottom: "0.5em",
})


const PostEditPage = (props: PostEditPageProps) => {

  const dispatch = useDispatch()
  const {language} = useI18next()
  const postLanguage = props.lang || language
  const currentSupportedLanguage = PlatformLanguage[postLanguage as keyof typeof PlatformLanguage] || PlatformLanguage.en


  const [state, setState] = useState(
    {
      currentPost: null as unknown as BlogPostEntity,
      editPost: null as unknown as BlogPostEntity,
      localPosts: [] as BlogPostEntity[],
      saved: props.postI18nId !== undefined
    })

  useEffect(() => {
    if (props.postI18nId) {
      blogApi.findPostDraftsByI18nId(props.postI18nId)
        .then(posts => {
          const currentPost = posts.find(post => post.attributes.language === postLanguage) ?? posts.find(post => post.attributes.original) ?? posts[0]
          setState({...state, currentPost, localPosts: posts})
        })
        .catch((error) => {
            dispatch(notification("Unable to get post to edit.", "warning", error))
          }
        )
    } else {
      blogApi.newPostDraft(currentSupportedLanguage).then(post => {
        setState({...state, currentPost: post, localPosts: [post]})
      }).catch((error) => {
          dispatch(notification("Unable to create new post.", "warning", error))
        }
      )
    }
  }, [])


  const changeCurrentPost = (lang: PlatformLanguage) => {
    const desiredLangPost = state.localPosts.find(post => post.attributes.language === lang)
    if (!state.saved) {
      dispatch(notification("Please save the current blog post before switching translations", "warning"))
      return
    }
    if (props.postI18nId) {
      // Existing post
      if (!desiredLangPost) {
        // New translation
        blogApi.newTranslationDraft(props.postI18nId, lang).then(post => {
            setState({...state, localPosts: [...state.localPosts, post], currentPost: post})
            dispatch(notification(`${lang} translation created`, "success"))
          }
        ).catch((error) => {
            dispatch(notification(`Unable to create translation: ${error.toString()}`, "warning"))
          }
        )
      } else {
        setState({...state, currentPost: desiredLangPost})
        dispatch(notification(`Switched to ${lang} translation`, "success"))
      }
    } else {
      // New post -> maybe new translation
      if (!desiredLangPost) {
        blogApi.newTranslationDraft(state.currentPost.attributes.i18nId, lang).then(post => {
            setState({...state, localPosts: [...state.localPosts, post], currentPost: post})
            dispatch(notification(`${lang} translation created`, "success"))
          }
        ).catch((error) => {
            dispatch(notification(`Unable to create translation: ${error.toString()}`, "warning"))
          }
        )
      } else {
        setState({...state, currentPost: desiredLangPost})
        dispatch(notification(`Switched to ${lang} translation`, "success"))
      }
    }
  }

  const [saving, setSaving] = useState(false)
  const saveDraft = useCallback(() => {
    if (saving) return
    setSaving(true)
    state.currentPost.save().then(() => {
      setSlugEditable(false)
      dispatch(notification("Post saved!", "success"))
      setState({...state, saved: true})
      setSaving(false)
    }).catch(error => {
      dispatch(notification(`Unable to save post: ${error.toString()}`, "warning"))
      setSaving(false)
    })
  }, [state, saving])

  const getFlags = () => {
    return Object.keys(PlatformLanguage).map((supportedLanguage) => {
      const language = supportedLanguage as keyof typeof PlatformLanguage
      if (flags[language] !== undefined) {
        const existingTranslation = state.localPosts.find(post => post.attributes.language === supportedLanguage)
        const currentLang = state.currentPost?.attributes.language === PlatformLanguage[language]
        return (
          <FlagSelection onClick={() => changeCurrentPost(PlatformLanguage[language])}
                         className={currentLang ? "selected" : ""}>
            <FlagFab>
              {existingTranslation ?
                <GrFormEdit title={"Edit existing translation"}/>
                :
                <GrFormAdd title={"Create new translation"}/>
              }
            </FlagFab>
            <div className={"svgflag"}>
              {getFlag(supportedLanguage)}
            </div>
          </FlagSelection>
        )
      }
    })
  }

  const handleChange = useCallback((element: keyof BlogPostWithParse, value: string) => {
    if (element === "tags") {
      state.currentPost.set(element, value.split(","))
    } else {
      state.currentPost.set(element, value)
    }
    setState({...state, saved: false, currentPost: state.currentPost})
  }, [state])

  const [slugEditable, setSlugEditable] = useState(false)
  useEffect(() => {
    setSlugEditable(props.postI18nId === undefined)
  }, [])

  // Images
  const textAreaRef = useRef<HTMLTextAreaElement>()
  const handleImageDrop = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.stopPropagation()
    ev.preventDefault()
    textAreaRef.current?.focus()
    const selection = textAreaRef.current?.selectionStart || 0
    const content = state.currentPost.attributes.content
    const start = content.substring(0, selection)
    const end = content.substring(selection)
    const imageMd = `![title](${ev.dataTransfer.getData("text/plain")})`
    state.currentPost.set("content", `${start} ${imageMd} ${end}`)
    setState({...state, saved: false, currentPost: state.currentPost})
  }
  const handleImageAdd = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      Promise.all(Object.values(event.target.files).map((image) => {
        return new Parse.File(slugify(image.name), image).save()
      })).then((newImages) => {
        state.currentPost.set("images", state.currentPost.attributes.images.concat(newImages))
        setState({...state, saved: false, currentPost: state.currentPost})
      })
    }
  }
  const handleImageDrag = (imageUrl: string, ev: React.DragEvent<HTMLDivElement>) => {
    ev.dataTransfer.setData("text/plain", imageUrl)
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleImageClick = (imageUrl: string, ev: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(ev.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSetAsJumbo = () => {
    setAnchorEl(null)
    const imageToJumbo = state.currentPost.attributes.images.find(image => image._url === anchorEl?.getAttribute("src"))
    state.currentPost.set("jumbo", imageToJumbo)
    setState({...state, saved: false, currentPost: state.currentPost})
  }
  const handleDeleteImage = () => {
    setAnchorEl(null)
    const imageToDelete = state.currentPost.attributes.images.find(image => image._url === anchorEl?.getAttribute("src"))
    state.currentPost.set("images", state.currentPost.attributes.images.filter(i => i._url !== imageToDelete?._url))
    setState({...state, saved: false, currentPost: state.currentPost})
  }

  const handleReset = () => {
    resetPostDraft(state.currentPost).then((post) => {
      dispatch(notification("Translation reseted", "success"))
      setState({...state, saved: false, currentPost: post})
    }).catch(e => {
      dispatch(notification(`Unable to reset translation:${e.toString()}`, "warning"))
    })
  }
  const checkSaved = () => {
    if (!state.saved) {
      dispatch(notification("Please save the draft first.", "warning"))
      return false
    }
    return true
  }
  const handlePublish = () => {
    if (checkSaved()) {
      publishDraft(state.currentPost).then((post) => {
        dispatch(notification("Blog Post promoted!", "success"))
      }).catch(e => {
        dispatch(notification("Unable to promote blog post.", "warning", e))
      })
    }
  }

  return state.localPosts ? (
    <Layout title={`Recalbox Blog > Edit ${props.postI18nId ?? "new post"}`}>
      <Box css={{padding: "2em", height: "100%"}}>
        <Grid container spacing={3} css={{height: "100%"}}>
          <Grid item xs={5} css={{height: "100%"}}>
            <Grid container xs={12} spacing={2}>
              {getFlags()}
              <GrowGrid item/>

              {props.user?.roles?.find(r => r === "moderator") &&
              <Grid item>
                <PublishDraftButton handlePromote={handlePublish}/>
              </Grid>
              }
              <Grid item>
                <ResetDraftButton handleReset={handleReset}/>
              </Grid>
              <Grid item>
                <Fab title={"Save draft"} color={state.saved ? "default" : "primary"} aria-label="save"
                     onClick={() => saveDraft()} size={"small"}>
                  <RiSave2Fill/>
                </Fab>
              </Grid>

            </Grid>
            <form noValidate autoComplete="off">
              <FullWithTextField label="Title"
                                 value={state.currentPost?.attributes.title}
                                 defaultValue="Title"
                                 variant="outlined"
                                 onChange={(e) => handleChange("title", e.target.value)}/>
              <FullWithTextField label="Slug"
                                 disabled={!slugEditable}
                                 value={state.currentPost?.attributes.i18nId}
                                 defaultValue="Title"
                                 variant="outlined"
                                 onChange={(e) => handleChange("i18nId", e.target.value)}/>
              <Grid container spacing={2}>
                <Grid item>
                  <FormControl css={{marginBottom: "0.5em"}} variant="outlined">
                    <InputLabel id="stateLabel">State</InputLabel>
                    <Select
                      labelId="stateLabel"
                      id="state"
                      value={state.currentPost?.attributes.state || "draft"}
                      onChange={(ev) => handleChange("state", ev.target.value as string)}
                      label="State"
                    >
                      <MenuItem value={"draft"}>Draft</MenuItem>
                      <MenuItem value={"deleted"}>Deleted</MenuItem>
                      <MenuItem value={"review"}>Review</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <GrowGrid item>
                  <FullWithTextField label="Tags (coma separated)"
                                     value={state.currentPost?.attributes.tags.join(",")}
                                     defaultValue=" "
                                     variant="outlined"
                                     onChange={(e) => handleChange("tags", e.target.value)}/>
                </GrowGrid>
              </Grid>

              <FullWithTextField
                inputRef={textAreaRef}
                id="standard-multiline-static"
                label="Content"
                multiline
                rows={15}
                variant="outlined"
                value={state.currentPost?.attributes.content || " "}

                onDragOver={(ev) => ev.preventDefault()}
                onDrop={(ev) => handleImageDrop(ev)}
                onChange={(e) => handleChange("content", e.target.value)}/>
              <Box>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleSetAsJumbo}>Set as Jumbo</MenuItem>
                  <MenuItem onClick={handleDeleteImage}>Delete</MenuItem>
                </Menu>
                Images:
                <Grid container>
                  <ImageUploader id={"images"}
                                 multiple
                                 handleImageAdd={handleImageAdd}
                                 images={state.currentPost?.attributes.images.map(image => image._url) ?? []}
                                 handleImageDrag={handleImageDrag}
                                 handleImageClick={handleImageClick}
                  />
                </Grid>
              </Box>
            </form>
          </Grid>
          <Grid item xs={7} css={{height: "100%", overflow: "auto"}}>
            {state.currentPost &&
            <PostCard post={state.currentPost.attributes} full/>
            }
          </Grid>
        </Grid>
      </Box>
    </Layout>
  ) : null
}

const element = (props: { lol: string }) => {
  return <div></div>
}

const mapState = (state: RootState) => {
  return {
    isLogged: state.profile.isLogged,
    user: state.profile.user,
  }
}
const connector = connect(mapState)
type PostEditPageProps = ConnectedProps<typeof connector> & RouteComponentProps & { postI18nId: string, lang: string };
export const PostEditPageRedux = connector(PostEditPage)

export const PostEdit: PrivatePage = {
  Page: PostEditPageRedux,
  getUrl: (postId?: string, lang?: string) => `/plus/blog/edit/${postId ? postId : ""}${lang ? "/" + lang : ""}`,
  minimumRole: "writer",
}
